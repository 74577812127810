html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background: #000000;
}

.luckyreel {
  overflow: hidden;
  background-image: url(../../assets/background.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.volume {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 44px;
  height: 44px;
  background: none;
  cursor: pointer;
  border: 0 !important;
  outline: none !important;
  background-size: 100% 100%;
  background-image: url(../../assets/unmute.png);
  z-index: 2;
  
  &.mute {
    background-image: url(../../assets/mute.png);
  }
}